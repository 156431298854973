import "../styles/styles.css"
import "../styles/tailwind.css"
import "aos/dist/aos.css"

import React, { useEffect } from "react"

import Aos from "aos"
import { Link } from "gatsby"

import ProductStrategyImage from "../assets/services-icon-ps.svg"
import CloseImage from "../assets/signs (1).svg"
import TagImage from "../assets/signs.svg"
import BulbImage from "../assets/terry-vlisidis-WsEbnsnKbUE-unsplash (2).png"
import { Layout2 } from "../components/layout2"
import SEO from "../components/seo"
import { SendUsMessage } from "./index"

const HeroSection = () => (
  <section id="hero" className="what-we-do-hero relative h-screen">
    <div className="bg-black absolute top-0 h-full w-full bg-opacity-75 z-0" />
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="700"
      className="flex flex-col relative  h-full justify-end md:justify-center  pb-32 md:pb-0 md:items-center mx-10"
    >
      <h1 className="text-5xl leading-none mb-4 md:mb-0 font-thin md:font-medium text-white text-center ">
        <span className="invisible md:visible">We build</span>{" "}
        <span className="font-black md:font-medium capitalize md:normal-case">
          solutions
        </span>{" "}
        that <span className="md:font-bold">Work.</span>
      </h1>
      <p className="text-center md:hidden text-white">
        We develop solutions tailored to your business needs
      </p>
    </div>
  </section>
)

const FeaturesSection = () => {
  const data = [
    {
      no: "01",
      title: "Product Strategy",
      content:
        "Hitting the bullseye isn't luck, it's Touchcore product strategy implemented insightfully. Our innovative strategists help determine where you're meant to go - and why - then decipher the optimum way to get there together.",
      link: "/product-strategy",
      img: ProductStrategyImage,
    },
    {
      no: "02",
      title: "User Experience Design (UI / UX)",
      content:
        "Design an engaging product that is easy-to-use, attractive and functional",
      link: "/ui-ux",
      img: require("./../assets/Tc3/UIUX.svg"),
    },
    {
      no: "03",
      title: "Web and  Mobile application Design",
      content:
        "Touchcore design overlays evocative user experience with stunning visual elegance to produce a complete package of purpose-driven, data-informed design that looks, feels, and works exactly right.",
      link: "/product-design",
      img: require("./../assets/Tc3/Product Design.svg"),
    },

    {
      no: "04",
      title: "Website Design & Development",
      content:
        "You need more than just a website. You need results. We turn underperforming websites around.",
      link: "/web-design",
      img: require("./../assets/Tc3/Web design.svg"),
    },
    {
      no: "05",
      title: "Software Development",
      content:
        "Bit by bit, Touchcore's expert engineers construct scalable, pixel-perfect custom software. We buld precision machines, written in best-in-class tools iincluding Node.js, React.js, Django, Laravel and Ruby to be clean, secure, stable and adaptable.",
      link: "/custom-development",
      img: require("./../assets/Tc3/Custom app development.svg"),
    },
    {
      no: "06",
      title: "Enterprise Solutions",
      content:
        "We offer tailor made strategy for our clients where they are made to feel at home with our business, UX and technology experts.",
      link: "/enterprise-solutions",
      img: require("./../assets/Tc3/Enterprise solutions.svg"),
    },
    {
      no: "07",
      title: "Website Audit Strategy",
      content:
        "All the benefits of an in-store experience, straight from your couch. From anywhere. Anytime.",
      link: "/web-audit-services",
      img: require("./../assets/audit-hg.png"),
    },
    {
      no: "08",
      title: "Website Retainer",
      content:
        "A comprehensive digital marketing strategy allows you to capture, engage, and convert your target audience in real-time.",
      link: "",
      img: require("./../assets/retainerx.png"),
    },
    {
      no: "08",
      title: "E-commerce",
      content:
        "All the benefits of an in-store experience, straight from your couch. From anywhere. Anytime.",
      link: "/ecommerce-solutions",
      img: require("./../assets/ecom-crown.png"),
    },
    {
      no: "10",
      title: "Digital Marketing",
      content:
        "A comprehensive digital marketing strategy allows you to capture, engage, and convert your target audience in real-time.",
      link: "/digital-marketing",
      img: require("./../assets/DmX.png"),
    },
  ]
  return (
    <section className="grid grid-col-1 md:grid-cols-2 gap-12 container px-5 md:px-12 my-20">
      {data.map((v, i) => (
        <Link
          key={"features" + i}
          data-aos="fade-left"
          data-aos-delay={200 + 50 * i}
          className="block h-full"
          to={v.link}
        >
          <div className="shadow-md hover:shadow-2xl h-full rounded-lg px-10 py-12">
            <img className="md:w-2/3 h-56" src={v.img} alt={v.title} />
            <span className="text-gray-700 mt-5">{v.no}</span>
            <h6 className="text-base font-black text-pgray">{v.title}</h6>
            <p className="text-xs mt-5">{v.content}</p>
          </div>
        </Link>
      ))}
    </section>
  )
}
const HowWeWork = () => {
  const data = [
    {
      title: "Understand",
      content:
        "Our development process starts with understanding your business, the needs, the pain points, and developing an appropriate timeline and project plan",
    },
    {
      title: "Research",
      content:
        "We dive in and research extensively on your industry, competitors, audience and interview internal stakeholders to construct a tailored strategy and design",
    },
    {
      title: "User Experience",
      content:
        "User experience is everything. How your users interact with your system is very important to us. This helps us define the key features, functionalities, and structure of your new application.",
    },
    {
      title: "Development",
      content:
        "The development process is broken down into sprints based on feature sets. Our agile-based development process will allow you to regularly review and assess what we're building.",
    },
  ]
  return (
    <GrayHowWeWork
      title={
        <>
          How we work <br />
          on your projects
        </>
      }
      data={data}
      image={BulbImage}
    />
  )
}
export const WhyChooseUs = () => {
  const data = [
    {
      title: "Source Code Ownership",
      image: TagImage,
      content:
        "We build it, you own it. It's that simple. Once we finish development, we pass over the complete source code so that your project is yours - no fees or strings attached. Whether you want to continue working with us for maintenance and updates, or take the project internal is up to you.",
    },
    {
      title: "No Third Parties",
      image: CloseImage,
      content:
        "You know what they say - three is a crowd. That's why we keep everything in-house. Our developers, designers, project managers, and everything in between are all our own vetted and trained team members. This allows us to ensure high-quality and on-time projects. ",
    },
    {
      title: "Local & Accountable",
      image: require("../assets/Handshake.svg"),
      content:
        "You know what they say - three is a crowd. That's why we keep everything in-house. Our developers, designers, project managers, and everything in between are all our own vetted and trained team members. This allows us to ensure high-quality and on-time projects. ",
    },
  ]
  return (
    <section className="bg-plightpink px-5 md:px-16 py-8 md:py-16">
      <div className="container">
        <h5
          data-aos="fade-left"
          className="text-3xl md:text-4xl text-center md:text-left text-pgray font-medium mb-8 mt-8 md:mt-16"
        >
          Why choose us
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {data.map((v, i) => (
            <div
              key={"whychoose" + i}
              data-aos="flip-up"
              data-aos-delay={200 + 100 * i}
              className="shadow bg-white p-6 text-pgray2 "
            >
              <div className="flex items-center justify-between">
                <h6 className="text-xl font-hairline mr-2">{v.title}</h6>
                <img alt="" className="w-20" src={v.image} />
              </div>
              <p className="mt-5">{v.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
const WhatWeDoPage = () => {
  useEffect(() => {
    console.log("initializing AOS")
    Aos.init({
      duration: 1200,
      offset: 200,
      delay: 270,
    })
  }, [])
  return (
    <Layout2>
      <SEO title="What we do" />
      <HeroSection />
      <FeaturesSection />
      <HowWeWork />
      <WhyChooseUs />
      <SendUsMessage />
    </Layout2>
  )
}
export default WhatWeDoPage

export const GrayHowWeWork = ({ data, image, title }) => {
  return (
    <section className="bg-pvdeepgray">
      <div className="container text-white px-5 md:px-16 py-16">
        <h4
          data-aos="fade-left"
          className="text-4xl leading-tight font-hairline mb-16 mt-10"
        >
          {title}
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          {data.map((v, i) => (
            <div data-aos="flip-up" className="bg-pdeepgray shadow p-12 ">
              <h6 className="text-2xl ">{v.title}</h6>
              <p className="text-small">{v.content}</p>
            </div>
          ))}
          <img
            data-aos="fade-left"
            className="hidden lg:block rounded-lg md:row-span-2 md:col-start-3 md:row-start-1 h-full object-cover"
            src={image}
            alt={"bulb"}
          />
        </div>
      </div>
    </section>
  )
}
